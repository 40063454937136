// ConsumerHome.js
import { CircularProgress } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect, useState } from "react"
import MainLayout from "../../layouts/MainLayout"
import api from "../../services"
import { phoneMask } from "../../utils/masks"
import { formatDate } from "../../utils/format"
import ModalCheck from "../Home/components/modal"
import ExportModal from "./components/ExportModal"
import * as S from "./style"
import { Link } from "react-router-dom"
import Papa from "papaparse"

function Consumidores() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [data, setData] = useState([])
  const [consumers, setConsumers] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsName, setParamsName] = useState({ name: "" })
  const [paramsEmail, setParamsEmail] = useState({ email: "" })

  const [body, setBody] = useState({
    type: "",
    content: "",
  })


  function openExportModal() {
    setExportModalOpen(true)
  }

  async function getData() {
    if (body.type === "name") {
      setParamsName({
        name: body.content,
      })
    } else if (body.type === "email") {
      setParamsEmail({
        email: body.content,
      })
    }

    try {
      setLoading(true)
      const res = await api.get(
        "/api/admin/users/consumidores",
        body.type === "name" ? { params: paramsName } : { params: paramsEmail }
      )

      setConsumers(res.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const generateCSV = (selectedFields) => {
    const csvData = consumers.map((consumer) =>
      selectedFields.reduce((obj, field) => {
        obj[field] = consumer[field]
        return obj
      }, {})
    )

    const csv = Papa.unparse(csvData)
    
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "consumers.csv")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <MainLayout title="Cadastro de consumidores do App">
      <S.Header>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="name">Nome</option>
          <option value="email">Email</option>
        </S.Select>
        <S.Input
          placeholder="Busque pelo nome ou email"
          onChange={(e) => setBody({ ...body, content: e.target.value })}
        />
        <S.Button disabled={loading} onClick={() => getData()}>
          Pesquisar
        </S.Button>

        <S.Button disabled={loading} onClick={() => openExportModal()}>
          Exportar para CSV
        </S.Button>
      </S.Header>

      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#919DF2" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="consumer table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Data de Criação</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {consumers?.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >

                  <TableCell component="th" scope="item">
                    <Link 
                      to={`/detail/consumidor/${item.id}`} 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {item?.name ? item?.name : "-"}
                    </Link> 
                  </TableCell>
                  <TableCell>{item?.email ? item?.email : "-"}</TableCell>
                  <TableCell>
                    {item?.telephone ? phoneMask(item?.telephone) : "-"}
                  </TableCell>
                  <TableCell>
                    {item?.created_at ? formatDate(item.created_at.split("T")[0]) : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {modalIsOpen ? (
        <ModalCheck
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          data={data}
        />
      ) : (
        ""
      )}

      {exportModalOpen && (
        <ExportModal
          exportModalOpen={exportModalOpen}
          setExportModalOpen={setExportModalOpen}
          onExport={generateCSV}
        />
      )}
    </MainLayout>
  )
}

export default Consumidores
