import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import * as React from "react"
import * as S from "./style"
import { useState } from "react"
import api from "../../../../services"

const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `40%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
}

export default function EditModal({ fetchCoupons, coupon }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(coupon.value);
  const [active, setActive] = useState(coupon.active ? "true" : "false");
  const [maxUses, setMaxUses] = useState(coupon.max_uses);

  const reset = () => {
    setValue("0.00");
    setActive("true");
    setMaxUses("1");
    setOpen(false);
  }

  const handleSubmit = async () => {
    console.log(value, active, maxUses);
    if (!value || !active || !maxUses) {
      alert("Preencha todos os campos!");
      return;
    }
    try {
      setLoading(true);
      await api.patch(
        `/api/admin/coupons/${coupon.id}`,
        {
          value: Number(value),
          active: active === "true",
          max_uses: Number(maxUses),
        }
      )
      fetchCoupons();
      reset();
      alert("Cupom editado com sucesso!");
    } catch(error) {
      console.log(error);
      alert(`Falha ao editar cupom: ${error.response.data.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <S.Button onClick={() => setOpen(true)}>
        Editar Cupom
      </S.Button>
      {open && (
        <Modal
          open={open}
          onClose={setOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <S.Icon>
              <CloseOutlinedIcon disabled={loading} onClick={() => setOpen(false)} />
            </S.Icon>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <S.ContainerTitle>
                <S.Text>Editar Cupom</S.Text>
                <FormGroup>
                  <S.SubtitleWrapper>
                    <S.Subtitle>Código</S.Subtitle>
                    <TextField
                      id="code"
                      type="text"
                      value={coupon.code.toUpperCase()}
                      inputProps={{
                        style: {
                          padding: 10,
                          height: 20,
                        },
                      }}
                      style={{
                        marginBottom: 10
                      }}
                      disabled
                    />
                  </S.SubtitleWrapper>
                  <S.SubtitleWrapper>
                    <S.Subtitle>Tipo</S.Subtitle>
                    <FormControl size="small">
                      <Select
                        id="type"
                        value={coupon.type}
                        style={{
                          marginBottom: 10
                        }}
                        disabled
                      >
                        <MenuItem value={"percent-discount"}>Desconto Percentual</MenuItem>
                        <MenuItem value={"value-discount"}>Desconto em Valor</MenuItem>
                        <MenuItem value={"plan-duration"}>Bônus de Duração em Dias no Plano</MenuItem>
                      </Select>
                    </FormControl>
                  </S.SubtitleWrapper>
                  <S.SubtitleWrapper>
                    <S.Subtitle>Valor</S.Subtitle>
                    <TextField
                      id="value"
                      type="number"
                      value={value}
                      onChange={(e) => {
                        const number = e.target.value.replace(/\D/g, "");
                        if (!number) {
                          setValue("0.00");
                          return;
                        }
                        const numericValue = parseInt(number, 10);
                        const formattedValue = (numericValue / 100).toFixed(2);
                        setValue(formattedValue);
                      }}
                      inputProps={{
                        style: {
                          padding: 10,
                          height: 20,
                        },
                      }}
                      style={{
                        marginBottom: 10
                      }}
                    />
                  </S.SubtitleWrapper>
                  <S.SubtitleWrapper>
                    <S.Subtitle>Status</S.Subtitle>
                    <FormControl size="small">
                      <Select
                        id="active"
                        value={active}
                        onChange={(ev) => setActive(ev.target.value)}
                        style={{
                          marginBottom: 10
                        }}
                      >
                        <MenuItem value={"true"}>Ativo</MenuItem>
                        <MenuItem value={"false"}>Inativo</MenuItem>
                      </Select>
                    </FormControl>
                  </S.SubtitleWrapper>
                  <S.SubtitleWrapper>
                    <S.Subtitle>Uso Máximo por Usuário</S.Subtitle>
                    <TextField
                      id="max_use"
                      type="number"
                      value={maxUses}
                      onChange={(e) => setMaxUses(e.target.value)}
                      inputProps={{
                        style: {
                          padding: 10,
                          height: 20,
                        },
                      }}
                    />
                  </S.SubtitleWrapper>
                </FormGroup>
                <S.BottomInfo>
                  <S.BottomButtons>
                    <S.DefaultButton disabled={loading} onClick={handleSubmit}>
                      <p>Editar</p>
                    </S.DefaultButton>
                  </S.BottomButtons>
                </S.BottomInfo>
              </S.ContainerTitle>
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  )
}